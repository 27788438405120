import React from "react";
import "./infisuite.scss";
import heroimg from "../../Images/infisuiteHero.svg";
import InfisuiteSec1 from "../../components/Common/InfisuiteSec1";
import InfisuiteSec2 from "../../components/Common/InfisuiteSec2";
import Footer from "../../components/Footer/Footer";
import { infisuiteData } from "../../Utils/insuiteData";
import { Slide } from "react-awesome-reveal";
const Infisuite = () => {
  return (
    <div className="infisuite">
      <section className="infisuite-hero">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-7 col-xs-12 d-flex justify-content-center align-items-center  ">
              <div className=" hero-left text-start">
                <Slide direction="right">
                  <h1 className="text-start ">
                    Our Comprehensive{" "}
                    <span style={{ color: "#184363" }}>
                      Digital Lending Solutions
                    </span>
                  </h1>
                </Slide>
                <Slide direction="left">
                  <p className="text-start ">
                    At Infitouch, we offer a complete suite of services to
                    streamline and scale your digital lending, from origination
                    to collection, improving efficiency, compliance, and
                    customer experience.
                  </p>
                </Slide>
                <button className="py-3 px-5 fs-5 mt-3 rounded">
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-sm-5 col-xs-12">
              <div>
                <img src={heroimg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <InfisuiteSec1 section={infisuiteData[0].infisuiteSec1[0]} />
      <InfisuiteSec2 section={infisuiteData[0].infisuiteSec2[0]} />
      <InfisuiteSec1 section={infisuiteData[0].infisuiteSec3[0]} />
      <InfisuiteSec2 section={infisuiteData[0].infisuiteSec4[0]} />
      <InfisuiteSec1 section={infisuiteData[0].infisuiteSec5[0]} />
      <InfisuiteSec2 section={infisuiteData[0].infisuiteSec6[0]} />
      <InfisuiteSec1 section={infisuiteData[0].infisuiteSec7[0]} />
      <InfisuiteSec2 section={infisuiteData[0].infisuiteSec8[0]} />
      <InfisuiteSec1 section={infisuiteData[0].infisuiteSec9[0]} />
      <InfisuiteSec2 section={infisuiteData[0].infisuiteSec10[0]} />

      <section className="infisuite-why ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center ">
              <div className="ms-3 mt-5 text-start infiwhy-left">
                <h1 className=" ">Why Choose Us?</h1>
                <p className="me-5 fs-5 mt-4">
                  At infitouch  we provide end-to-end digital lending services
                  designed to optimise your entire loan lifecycle—from
                  origination to recovery. Our solutions ensure you remain
                  compliant, improve risk management, and enhance customer
                  engagement, all while increasing operational efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="card py-5 px-3 mx-2 shadow text-start infiwhy1">
                    <h5 className="pb-4">Comprehensive Solutions</h5>
                    <p>
                      We offer everything you need to run your digital lending
                      business smoothly.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12 ">
                  <div className="card py-5 px-3 mx-2 mt-4  shadow  text-start ">
                    <h5 className="pb-4">Tailored to Your Needs</h5>
                    <p>
                      Custom solutions designed for your unique requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <div className="card py-5 px-3 mx-2  shadow text-start infiwhy3">
                    <h5 className="pb-4">Seamless Integration</h5>
                    <p>
                      Our platform integrates easily with your existing systems,
                      providing a unified experience.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-12">
                  <div className="card py-5 px-3 mx-2  mt-4 shadow  text-start infiwhy4">
                    <h5 className="pb-4">End to End Automation</h5>
                    <p>
                      From loan origination to collections, we automate key
                      processes to save you time and reduce errors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Infisuite;
