import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import AppRoutes from "./Layouting/AppRoutes";
import Infisuite from "./Pages/Infisuite/Infisuite";
function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
