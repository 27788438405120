import React from "react";
import footerLogo from "../../Images/footerLogo1.svg";
import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = (section) => {
    setTimeout(() => {
      const scrollSection = document.querySelector(`${section}`);
      if (scrollSection) {
        window.scroll({
          top: scrollSection.offsetTop,
          behavior: "smooth",
        });
      }
    }, 300);
  };

  return (
    <div className="mt-5 footer">
      <footer className=" pt-5 mt-5 container">
        <div className="">
          <button
            onClick={() => handleClick("#nav")}
            className="scroll-up fs-2 py-3 px-5 "
          >
            <i class="fa-solid fa-arrow-up" style={{ color: "#ffffff" }}></i>
          </button>
        </div>
        <div className="row py-5">
          <div className="col-lg-4 text-start">
            <div>
              <img src={footerLogo} />
            </div>
            <div className="py-4  text-start">
              <div className="icon-div px-3 py-2">
                <i className="fa-brands fa-facebook-f"></i>
              </div>
              <div className="icon-div px-3 py-2">
                {" "}
                <i className="fa-brands fa-instagram"></i>
              </div>
              <div className="icon-div  px-3 py-2">
                <i className="fa-brands fa-linkedin-in"></i>
              </div>
              <div className="icon-div  px-3 py-2">
                {" "}
                <i className="fa-brands fa-youtube"></i>
              </div>
              <p className="pt-3 fs-5 pe-5">Email: info@infitouch.in</p>
              <p>
                Communication address:Vipul Plaza, Sector 81, Faridabad - 121002
              </p>{" "}
              <p>Registered address: Vijeta Society,Faridabad,Haryana,121004</p>
            </div>
          </div>
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-sm-3 col-xs-12 mb-4 d-flex flex-column gap-3 text-start">
                <h4>Company</h4>
                <Link to="/">Home</Link>
                <Link to="/aboutUs">About Us</Link>
                <Link to="/aboutUs" onClick={() => handleClick("#faq")}>
                  FAQs
                </Link>
                <a href="#">Privacy Policy</a>
                <a href="#">Term And Conditions</a>
              </div>
              <div className="col-sm-3 col-xs-12 d-flex flex-column gap-3 text-start pb-4">
                <h4>Quick Links</h4>
                <Link to="/" onClick={() => handleClick("#flow")}>
                  Lending Flow
                </Link>
                <Link to="/" onClick={() => handleClick("#integration")}>
                  Third Party Integration{" "}
                </Link>
                <Link to="/" onClick={() => handleClick("#solutions")}>
                  Solutions
                </Link>
                <Link to="/" onClick={() => handleClick("#services")}>
                  Services
                </Link>
                <Link to="/" onClick={() => handleClick("#partners")}>
                  Partners
                </Link>
              </div>
              <div className="col-sm-6 col-xs-12 ">
                <h4 className="text-start pb-4">Be Our Subscribers</h4>
                <div className="d-flex justify-content-between p-2 my-3  border rounded">
                  <p>Support@infitouch.com</p>
                  <button
                    style={{ backgroundColor: "white", color: "#1843632" }}
                    className="rounded border-0"
                  >
                    Subscribe
                  </button>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className=" px-2 py-3 fs-5 rounded"
                >
                  <Link to="/contactUs" style={{ color: "#184363" }}>
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <p className="text-center py-5 fw-light">
            ©Infitouch labs LLP. | Empowering Digital Transformation | All
            Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
