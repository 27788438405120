import React, { useState } from "react";
import "./navbar.scss";
import Logo from "../../Images/navbarLogo.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const clickHandler = (section) => {
    setTimeout(() => {
      const scrollSection = document.querySelector(`${section}`);
      if (scrollSection) {
        window.scroll({
          top: scrollSection.offsetTop - 20,
          behavior: "smooth",
        });
      }
    }, 300);
  };
  return (
    <div>
      <nav
        className="navbar fixed-top navbar-expand-lg bg-body-tertiary pt-4"
        id="nav"
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse nav navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav gap-3 my-2 py-2 px-3 rounded">
              <Link to="/">
                <li className="nav-item">HOME</li>
              </Link>
              <Link to="/aboutUs">
                <li className="nav-item">ABOUT US</li>
              </Link>
              <Link to="/" onClick={() => clickHandler("#benefits")}>
                <li className="nav-item">BENEFITS</li>
              </Link>
              <Link to="/" onClick={() => clickHandler("#solutions")}>
                <li className="nav-item">SOLUTIONS</li>
              </Link>
              <Link to="/" onClick={() => clickHandler("#flow")}>
                {" "}
                <li className="nav-item">LENDING JOURNEY</li>
              </Link>
              <Link to="/infisuite">
                <li className="nav-item">INFISUITE</li>
              </Link>
            </ul>

            <Link to="/contactUs">
              <button className=" py-2 my-2 px-3 rounded">CONTACT US</button>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
