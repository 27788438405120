import img1 from "../Images/infisuite1.svg";
import img2 from "../Images/infisuite2.svg";
import img3 from "../Images/infisuite3.svg";
import img4 from "../Images/infisuite4.svg";
import img5 from "../Images/infisuite5.svg";
import img6 from "../Images/infisuite6.svg";
import img7 from "../Images/infisuite7.svg";
import img8 from "../Images/infisuite8.svg";
import img9 from "../Images/infisuite9.svg";
import img10 from "../Images/infisuite10.svg";

export const infisuiteData = [
  {
    infisuiteSec1: [
      {
        img: img1,
        heading1: "loan Origination As A service",
        heading2:
          "Our Loan Origination Service streamlines the entire loan application process, ensuring quick and efficient loan approvals",
        points: [
          {
            heading: "Loan Application & Pre Screening",
            content:
              "Simplify loan applications with an intuitive interface, automated checks, and pre-screening for suitability",
          },
          {
            heading: "Customer KYC & AML Compliance",
            content:
              "Automate KYC and AML checks to ensure compliance with regulatory standards",
          },
          {
            heading: "Credit Scoring & Risk Assessment",
            content:
              "Leverage advanced algorithms and data analytics for creditworthiness evaluation and risk assessment",
          },
          {
            heading: "Loan Approval Workflow",
            content:
              "An automated workflow for processing applications, approvals, and documents, speeding up tAT",
          },
        ],
      },
    ],

    infisuiteSec2: [
      {
        img: img2,
        heading1: "Credit Underwriting as a Service ",
        heading2:
          "Our underwriting service helps you make informed, data driven decisions, reducing risk and enhancing efficiency",
        points: [
          {
            heading: "Automated Underwriting",
            content:
              "Automate loan approval decisions based on credit scores and financial health using predefined criteria",
          },
          {
            heading: "Customer KYC & AML Compliance",
            content:
              "Automate KYC and AML checks to ensure compliance with regulatory standards",
          },
          {
            heading: "Realtime Fraud Detection",
            content:
              "Implement advanced fraud detection tools to Instantly flag suspicious activities during underwriting",
          },

          {
            heading: "Enhanced Decision Making",
            content:
              "Improve loan decision accuracy with data-driven insights and real-time processing",
          },
        ],
      },
    ],
    infisuiteSec3: [
      {
        img: img3,
        heading1: "Loan Disbursement as a Service ",
        heading2:
          "We provide an automated loan disbursement system that ensures your loans reach customers quickly and securely",
        points: [
          {
            heading: "Seamless Disbursement Channels ",
            content:
              "Enable quick fund transfers through preferred channels for immediate customer access",
          },
          {
            heading: "Instant Payment Tracking",
            content:
              "Track disbursements in real-time, notifying customers instantly about loan status updates",
          },
          {
            heading: "Automated Payment Confirmation",
            content:
              "Confirm disbursements and payments with minimal manual effort, ensuring operational efficiency",
          },
          {
            heading: "Secure Fund Transfers",
            content:
              "Use encrypted channels to safeguard customer transactions and maintain payment security",
          },
        ],
      },
    ],
    infisuiteSec4: [
      {
        img: img4,
        heading1: "Compliance as a Service ",
        heading2:
          "Stay compliant with revolving regulations, while we handle the complexities of legal and regulatory requirements for you",
        points: [
          {
            heading: "Regulatory Compliance Management",
            content:
              "Ensure adherence to KYC, AML, and GDPR with automated compliance processes",
          },
          {
            heading: "Audit & Reporting",
            content:
              "Generate audit trails and reports for transparent regulatory checks and balances",
          },
          {
            heading: "Document Management & e-Signatures",
            content:
              "Secure digital management of loan-related documents and customer signatures to protect data integrity",
          },
          {
            heading: "Real-time Compliance Monitoring",
            content:
              "Track compliance status in real-time, ensuring immediate action on any issues",
          },
        ],
      },
    ],
    infisuiteSec5: [
      {
        img: img5,
        heading1: " Collections as a Service ",
        heading2:
          "Maximise recovery with our automated and customizable collections processes, designed to enhance loan repayment efficiency",
        points: [
          {
            heading: "Automated Collections",
            content:
              "Automate reminders and payment schedules to streamline repayments",
          },
          {
            heading: "Recovery Workflow",
            content:
              "Create a flexible workflow for overdue loans with reminders, legal actions, and default handling",
          },
          {
            heading: "Loan Restructuring & Recovery",
            content:
              " Offer flexible restructuring options to help borrowers manage repayments while improving recovery rates",
          },
          {
            heading: "Debt Recovery Analytics",
            content:
              "Monitor the performance of collection efforts with data-driven insights to optimize strategies",
          },
        ],
      },
    ],
    infisuiteSec6: [
      {
        img: img6,
        heading1: "Marketing as a Service ",
        heading2:
          "Boost your customer acquisition efforts with our marketing solutions designed specifically for lending businesses",
        points: [
          {
            heading: "Targeted Campaigns & Lead Generation",
            content:
              "Launch precise campaigns for specific borrower segments using demographic and behavioural insights",
          },
          {
            heading: "Digital Marketing Strategy",
            content:
              "Boost visibility with SEO, social media, email marketing, and paid advertisements",
          },
          {
            heading: "Customer Segmentation",
            content:
              "Deliver tailored offers and promotions to enhance customer engagement and conversions",
          },
          {
            heading: "Analytics & Insights",
            content:
              "Optimize marketing efforts using performance analytics and data-driven strategies",
          },
        ],
      },
    ],
    infisuiteSec7: [
      {
        img: img7,
        heading1: "Client Dashboards & Analytics",
        heading2:
          "Get real time visibility into your lending operations with comprehensive dashboards and reporting tools",
        points: [
          {
            heading: "Custom Dashboards",
            content:
              "Design custom dashboards to track loan volumes, disbursements, repayments, and collections effectively",
          },
          {
            heading: "Real-time Analytics ",
            content:
              "Track real-time KPIs, including loan performance, customer behavior, and financial health",
          },
          {
            heading: "Loan Portfolio Tracking",
            content:
              "Track and assess the health of your loan portfolio with visual charts, graphs, and detailed analytics",
          },
          {
            heading: "Data-Driven Reporting",
            content:
              "Generate reports to assess trends, risks, and opportunities within your loan operations",
          },
        ],
      },
    ],
    infisuiteSec8: [
      {
        img: img8,
        heading1: "App & Website Development as a Service",
        heading2:
          "We provide end to end development services to create a seamless experience for both you and your customers",
        points: [
          {
            heading: "Customer Facing Mobile  App",
            content:
              " Create a user-friendly app for loan applications, repayments, and support",
          },
          {
            heading: "Admin Portal",
            content:
              "Secure platform for managing loan processes, including applications, disbursements, and collections",
          },
          {
            heading: "Custom Website",
            content:
              "Build responsive websites showcasing services, products, and customer portals",
          },
          {
            heading: "Seamless Integration",
            content:
              "Ensure smooth workflows by integrating apps and websites with internal systems",
          },
        ],
      },
    ],
    infisuiteSec9: [
      {
        img: img9,
        heading1: "Communication as a Service",
        heading2:
          "Streamline customer interactions and enhance engagement with automated and personalized communication",
        points: [
          {
            heading: "Real-time Notifications",
            content:
              "Instantly notify customers about loan status, approvals, disbursements, and payments",
          },
          {
            heading: "Personalized Messaging",
            content:
              "Tailor messages based on customer data to keep them engaged at every step",
          },
          {
            heading: "Multi-channel Communication",
            content:
              "Reach customers via SMS, email, and in-app notifications to provide a seamless experience",
          },
          {
            heading: "Customer Feedback",
            content:
              " Collect and analyze customer feedback through automated surveys to improve service quality",
          },
        ],
      },
    ],
    infisuiteSec10: [
      {
        img: img10,
        heading1: " Loan Management System (LMS) as a Service",
        heading2:
          "Our Loan Management System offers end-to-end automation for managing active loans, tracking payments, and ensuring smooth loan servicing",
        points: [
          {
            heading: "Payment Tracking",
            content:
              "Monitor repayment schedules, overdue amounts, and automate reminders to borrowers",
          },
          {
            heading: "Account Management",
            content:
              "Provide customers with a self-service portal to manage loan accounts and view transaction histories",
          },
          {
            heading: "Compliance Monitoring",
            content:
              "Ensure all active loans comply with evolving regulatory requirements",
          },
          {
            heading: "Detailed Reporting",
            content:
              "Generate customizable reports to track loan performance, portfolio health, and customer analytics",
          },
        ],
      },
    ],
  },
];
