import React, { useEffect, useState } from "react";
import "./infisuiteSec.scss";
import { Slide } from "react-awesome-reveal";

const InfisuiteSec1 = ({ section }) => {
  return (
    <section className=" infisuite-sec1 mt-5">
      <div className="container pt-5">
        <div className="heading1-div rounded mb-3 px-4 py-2">
          <h6> {section.heading1} </h6>
        </div>
        <div>
          <h3 className="pb-4 lh-base">{section.heading2}</h3>
        </div>
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <Slide direction="left">
              <img src={section.img} />
            </Slide>
          </div>
          <div className="col-lg-6">
            <div className="">
              <div className="scroll-sec1">
                {section.points.map((point, index) => (
                  <div
                    key={index}
                    className={`px-5 py-3 text-start my-3  mx-3 rounded sec1-right`}
                  >
                    <h5>{point.heading}</h5>
                    <p className="">{point.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfisuiteSec1;
