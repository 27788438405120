import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as Routers from "./Router";
import { OurRoutes } from "./Route";

const AppRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path={OurRoutes.mainPage} element={<Routers.MainPage />}>
          <Route index element={<Routers.Home />} />
          <Route path={OurRoutes.contactUs} element={<Routers.ContactUs />} />
          <Route path={OurRoutes.infisuite} element={<Routers.Infisuite />} />
          <Route path={OurRoutes.about} element={<Routers.About />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
