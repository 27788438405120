import React from "react";
export const MainPage = React.lazy(() => import("../components/Layout"));
export const Home = React.lazy(() => import("../Pages/Home/Home"));
export const About = React.lazy(() => import("../Pages/About/About"));
export const Infisuite = React.lazy(() =>
  import("../Pages/Infisuite/Infisuite")
);
export const ContactUs = React.lazy(() =>
  import("../Pages/ContactUs/ContactUs")
);
